.commentComposer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.commentComposer__top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
  max-height: 12rem;
  display: flex;
  flex-direction: column;
}

.commentComposer__mentionContainer {
  max-height: 100%;
  overflow-y: scroll;
}

.commentComposer__container {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background-color: var(--asc-color-background-default);
  border-top: 1px solid var(--asc-color-base-shade4);
  width: 100%;
  flex-direction: row;
}

.commentComposer__avatar {
  width: 2rem;
  height: 2rem;
  border-radius: var(--asc-border-radius-full);
  margin: 0 auto;
  display: flex;
  margin-bottom: 0.25rem;
  flex-shrink: 0;
}

.commentComposer__button {
  color: var(--asc-color-primary-default);
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.commentComposer__button:hover {
  background-color: transparent !important;
}

.commentComposer__button:disabled {
  color: var(--asc-color-primary-shade2);
}

.commentComposer__input {
  width: 100%;
  background-color: var(--asc-color-base-shade4);
  border-radius: 1.25rem;
  padding: 0.625rem 0.75rem;
}

.commentComposer__replyContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 1rem;
  background-color: var(--asc-color-base-shade4);
}

.commentComposer__replyContainer__text {
  font-size: var(--asc-text-font-size-sm);
  font-weight: var(--asc-text-font-weight-normal);
  line-height: var(--asc-line-height-sm);
  color: var(--asc-color-base-shade1);
}

.commentComposer__replyContainer__username {
  font-weight: var(--asc-text-font-weight-bold);
}

.commentComposer__replyContainer__closeButton {
  fill: var(--asc-color-base-shade2);
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.commentComposer__disableContainer {
  display: flex;
  justify-content: center;
  padding: 0.625rem 1rem;
  gap: var(--asc-spacing-m1);
  align-items: center;
  border-top: 1px solid var(--asc-color-base-shade4);
  color: var(--asc-color-base-shade2);
}
