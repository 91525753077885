.closeIcon {
  display: flex;
  justify-content: flex-end;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 0.375rem;
  cursor: pointer;
  margin-left: auto;
  color: var(--asc-color-base-shade3);

  &.svg-inline--fa {
    width: auto;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  overflow-y: auto;
  display: flex;
  padding: var(--asc-spacing-m2) 0;
  background: rgb(23 24 28 / 80%);
  animation-duration: 0.3s;
  animation-name: appear;
  margin-top: 0 !important;
}

.modalWindow {
  margin: auto;
  border-radius: var(--asc-border-radius-lg);
  max-width: 32.5rem;
  min-width: 20rem;
  padding: 1.5rem;
  background-color: var(--asc-color-background-default);
}

.modalWindow:focus {
  outline: none;
}

.smallModalWindow {
  width: 16.8rem;
}

.title {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: var(--asc-color-base-default);
  font-size: 1.25rem;
  font-weight: 600;
}

.content {
  margin-top: 0.5rem;
  line-height: 1.25rem;
  font-size: 0.875rem;
  color: var(--asc-color-base-shade1);
}

.footer {
  margin-top: 0.5rem;
  padding-top: 1rem;
}

@media (width <= 768px) {
  .modalWindow {
    max-width: 16.8rem;
  }

  .title {
    font-size: 1rem;
  }

  .footer {
    display: flex;
    justify-content: center;
  }
}
