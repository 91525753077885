.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--asc-color-base-inverse);
  border: 1px solid var(--asc-color-base-shade4);
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--asc-spacing-m1);
  background-color: var(--asc-color-base-inverse);
  color: var(--asc-color-base-default);
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid var(--asc-color-base-shade4);
}

.roundedHeader {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: var(--asc-spacing-m1);
}

.scroller {
  height: 100%;
}

.composeBarContainer {
  padding: var(--asc-spacing-m1);
  background-color: var(--asc-color-base-inverse);
}

.nestedBackdrop {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 50%);
  z-index: -1;
}

.commentTrayContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.commentListContainer {
  overflow-y: auto;
  flex-grow: 1;
  padding: var(--asc-spacing-m1);
}

.mobileSheetComposeBarContainer {
  width: 100%;
}
