.reactButton {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.25rem;
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: none;
}

.reactButton * {
  -webkit-touch-callout: none;
  user-select: none;
}

.reactButton__icon {
  width: 1.25rem;
  height: 1.25rem;
}

.reactButton__icon[data-has-my-reaction='false'] {
  fill: var(--asc-color-base-shade2);
}

.reactButton__reactionsText {
  color: var(--asc-color-base-shade2);
}

.reactButton__reactionsText[data-has-my-reaction='true'] {
  color: var(--asc-color-primary-default);
  text-transform: capitalize;
}

.reactButton__panel {
  position: absolute;

  /* bottom: calc(0.25rem + 0.44rem); */
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 0.81rem));
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem 0.375rem;
  border-radius: 1.75rem;
  background-color: var(--asc-color-background-default);
  box-shadow: var(--asc-box-shadow-03);
}

.reactButton__panel__reaction {
  padding: 0.25rem 0.44rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.reactButton__panel__reaction__text {
  display: none;
  padding: 0 0.375rem;
  align-items: center;
  gap: 0.0625rem;
  border-radius: 1.25rem;
  background: rgb(0 0 0 / 50%);
  color: var(--asc-color-white);
  text-align: center;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 180% */
  letter-spacing: -0.0063rem;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.reactButton__panel__reaction:hover > .reactButton__panel__reaction__text,
.reactButton__panel__reaction[data-touch-over='true'] > .reactButton__panel__reaction__text {
  display: flex;
}

.reactButton__panel__reaction:hover,
.reactButton__panel__reaction[data-touch-over='true'] {
  animation: reaction-scale 250ms ease-out;
  scale: 1.5;
}

.reactButton__panel__reaction[data-active='true'] {
  animation: reaction-click 250ms ease-out;
}

@keyframes reaction-scale {
  from {
    scale: 1;
  }

  to {
    scale: 1.5;
  }
}

@keyframes reaction-click {
  from {
    scale: 1.5;
  }

  to {
    scale: 2;
  }
}
