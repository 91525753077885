.editorPlaceholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  top: 0;
  user-select: none;
  pointer-events: none;
}

.editorParagraph {
  height: 100%;
  width: 100%;
  color: var(--asc-color-base-default);
  line-height: var(--asc-line-height-md);
  font-size: var(--asc-text-font-size-md);
  margin: 0;
}

.editorContainer {
  width: 100%;
  height: 100%;
  color: var(--asc-color-base-default);
  max-height: calc(var(--asc-line-height-md) * var(--asc-max-lines) + (0.62rem * 2));
  position: relative;
}

.editorEditableContent {
  height: max-content;
  max-height: calc(var(--asc-line-height-md) * var(--asc-max-lines));
  overflow-y: scroll;
}

.editorContainer :focus {
  border: none;
  outline: none;
}

.editorLink {
  color: var(--asc-color-primary-shade1);
  text-decoration: none;
}

.commentInput__mentionInterceptor {
  width: 100%;
  height: 1px;
  background-color: var(--asc-color-background-default);
}
