.postReplyComment {
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 0.5rem;
}

.postReplyComment__avatar {
  margin-right: 0.75rem;
  display: inline-block;
}

.postReplyComment__details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.postReplyComment__content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;
  align-items: start;
  background-color: var(--asc-color-base-shade4);
  border-radius: 0 0.75rem 0.75rem;
  padding: 0.75rem;
  max-width: max-content;
}

.postReplyComment__content__username {
  color: var(--asc-color-base-default);
}

.postReplyComment__content__text {
  color: var(--asc-color-base-default);
}

.postReplyComment__secondRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.postReplyComment__secondRow__leftPane {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.postReplyComment__secondRow__rightPane {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.postReplyComment__secondRow__rightPane__like {
  width: 1.25rem;
  height: 1.25rem;
}

.postReplyComment__secondRow__like {
  cursor: pointer;
  color: var(--asc-color-base-shade2);
}

.postReplyComment__secondRow__like[data-is-liked='true'] {
  color: var(--asc-color-primary-default);
}

.postReplyComment__secondRow__reply {
  color: var(--asc-color-base-shade2);
}

.postReplyComment__secondRow__actionButton {
  fill: var(--asc-color-secondary-shade2);
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

.postReplyComment__viewReply_button {
  display: flex;
  padding: 0.3125rem 0.75rem 0.3125rem 0.5rem;
  cursor: pointer;
  align-items: flex-start;
  gap: 0.25rem;
  background: var(--asc-color-background-default);
  border: 1px solid var(--asc-color-base-shade4);
  border-radius: 0.25rem;
  width: max-content;
}

.postReplyComment__viewReply_icon {
  color: var(--asc-color-secondary-shade1);
  width: 1rem;
  height: 1rem;
}

.postReplyComment__viewReply_text {
  color: var(--asc-color-secondary-shade1);
}

.postReplyComment__actionButton {
  display: flex;
  gap: 0.75rem;
  padding: 1rem 0;
  align-items: center;
  cursor: pointer;
}

.postReplyComment__actionButton__icon {
  color: var(--asc-color-base-default);
  width: 1.5rem;
  height: 1.5rem;
}

.postReplyComment__actionButton__text {
  color: var(--asc-color-base-default);
}

.postReplyComment__deleteComment_container {
  display: flex;
  padding: 0.31rem 0.5rem;
  gap: 0.25rem;
  background-color: var(--asc-color-secondary-shade4);
  border-radius: var(--asc-border-radius-sm);
  width: max-content;

  /* justify-content: center; */
  align-items: center;
  margin-bottom: var(--asc-spacing-s2);
}

.postReplyComment__deleteComment_icon {
  width: 1rem;
  height: 1rem;
  color: var(--asc-color-secondary-shade2);
}

.postReplyComment__deleteComment_text {
  color: var(--asc-color-secondary-shade2);
}

.postReplyComment__edit {
  display: flex;
  gap: 0.5rem;
}

.postReplyComment__edit__inputWrap {
  width: 100%;
}

.postReplyComment__edit__input {
  display: flex;
  height: 7.5rem;
  padding: 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
  background-color: var(--asc-color-base-shade4);
  width: 100%;
  border-radius: 0 0.75rem 0.75rem;
}

.postReplyComment__edit__buttonWrap {
  display: flex;
  gap: var(--asc-spacing-s1);
  justify-content: flex-end;
  margin-top: var(--asc-spacing-s1);
}

.postReplyComment__edit__button {
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.postReplyComment__edit__cancelButton {
  color: var(--asc-color-secondary-shade1);
  border-radius: var(--asc-border-radius-sm);
  border: 1px solid var(--asc-color-secondary-shade1);
  background: var(--asc-color-background-default);
}

.postReplyComment__edit__saveButton {
  color: var(--asc-color-white);
  border-radius: var(--asc-border-radius-sm);
  background: var(--asc-color-primary-default);
}

.postReplyComment__secondRow__timestamp {
  display: flex;
  color: var(--asc-color-base-shade2);
  white-space: pre;
}
