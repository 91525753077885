.badge {
  position: relative;
  border-radius: var(--asc-border-radius-full);
  padding: var(--asc-spacing-none) var(--asc-spacing-xxs3);
}

.badge::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--asc-color-base-shade1);
  opacity: 0.5;
  border-radius: inherit;
}

.badge__icon {
  width: 0.75rem;
  height: 0.75rem;
}

.badge__text {
  font-size: var(--asc-text-font-size-xs);
  line-height: var(--asc-line-height-sm);
  color: var(--asc-color-white);
}

.badge__child {
  position: relative;
  display: flex;
  gap: var(--asc-spacing-xxs1);
  align-items: center;
}
