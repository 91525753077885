.saveButton {
  cursor: pointer;
  color: var(--asc-color-white);
  background-color: var(--asc-color-primary-default);
}

.saveButton:disabled {
  cursor: pointer;
  color: var(--asc-color-white);
  background-color: var(--asc-color-primary-shade2);
}
