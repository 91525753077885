.postContent {
  background-color: var(--asc-color-background-default);
  padding: 0.25rem 1rem 0.75rem;
}

.postContent__bar {
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  align-items: center;
  gap: 0.5rem;
}

.postContent__bar__userAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.postContent__bar__detail {
  overflow: hidden;
}

.postContent__bar__information__subtitle {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.postContent__bar__information__subtitle__moderator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.postContent__bar__information__subtitle__brand {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.postContent__bar__information__subtitle__separator {
  color: var(--asc-color-base-shade2);
}

.postContent__bar__actionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  cursor: pointer;
  gap: 0.5rem;
}

.postContent__content_and_reactions {
  padding-left: 0.25rem;
}

.postContent__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 0;
}

.postContent__divider {
  width: 100%;
  background-color: var(--asc-color-base-shade4);
  height: 0.0625rem;
}

.postContent__reactionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.7rem;
}

.postContent__reactionBar__leftPane {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.75rem;
}

.postContent__reactionBar__rightPane {
  display: flex;
  align-items: center;
  justify-content: center;
}

.postContent__reactionBar__leftPane__icon {
  width: 1.25rem;
  height: 1.25rem;
}

.postContent__reactionBar__leftPane__iconImg {
  width: 1.25rem;
  height: 1.25rem;
}

.postTitle {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(0, max-content);
  grid-auto-flow: column;
  gap: 0.25rem;
}

.postTitle[data-show-target-community='true'] {
  grid-template-columns: minmax(0, max-content) minmax(50%, 1fr);
}

.postTitle__text {
  color: var(--asc-color-base-default);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.postTitle__icon {
  fill: var(--asc-color-base-shade1);
  height: 0.75rem;
  width: 1rem;
}

.postTitle__user__container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 0.25rem;
  align-items: center;
  max-width: 100%;
}

.postTitle__user__container[data-show-brand-badge='true'][data-show-target='true'] {
  grid-template-columns: minmax(0, min-content) min-content min-content;
}

.postTitle__user__container[data-show-brand-badge='false'][data-show-target='true'],
.postTitle__user__container[data-show-brand-badge='true'][data-show-target='false'] {
  grid-template-columns: minmax(0, min-content) min-content;
}

.postTitle__brandIcon {
  width: 1.25rem;
  height: 1.25rem;
}

.postTitle__community {
  display: grid;
  grid-template-columns: minmax(0, min-content);
  grid-auto-flow: column;
  gap: 0.25rem;
  align-items: center;
  max-width: 100%;
}

.postTitle__community[data-show-private-badge='true'][data-show-official-badge='true'] {
  grid-template-columns: min-content minmax(0, min-content) min-content;
}

.postTitle__community[data-show-private-badge='true'][data-show-official-badge='false'] {
  grid-template-columns: min-content minmax(0, min-content);
}

.postTitle__community[data-show-private-badge='false'][data-show-official-badge='true'] {
  grid-template-columns: minmax(0, min-content) min-content;
}

.postTitle__communityText {
  color: var(--asc-color-base-default);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.postTitle__community__privateIcon {
  width: 0.75rem;
  height: 0.75rem;
}

.postTitle__community__verifiedIcon {
  width: 1.25rem;
  height: 1.25rem;
}

.postContent__comment {
  display: grid;
}

.postContent__reactions_and_comments {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.postContent__reactionsBar {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 0.25rem;
}

.postContent__commentsCount {
  color: var(--asc-color-base-shade2);
}

.postContent__reactionsBar__reactions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}

.postContent__reactionsBar__reactions__icon {
  width: 1.25rem;
  height: 1.25rem;
}

.postContent__reactionsBar__reactions__icon:nth-last-child(1) {
  transform: translateX(0);
}

.postContent__reactionsBar__reactions__icon:nth-last-child(2) {
  transform: translateX(-0.25rem);
}

.postContent__reactionsBar__reactions__icon:nth-last-child(3) {
  transform: translateX(-0.5rem);
}

.postContent__reactionsBar__reactions__icon:nth-last-child(4) {
  transform: translateX(-0.75rem);
}

.postContent__reactionsBar__reactions__icon:nth-last-child(5) {
  transform: translateX(-1rem);
}

.postContent__reactionsBar__reactions__icon:nth-last-child(6) {
  transform: translateX(-1.25rem);
}

.postContent__reactionsBar__reactions__count {
  color: var(--asc-color-base-shade2);
}

.postContent__bar__information__editedTag {
  color: var(--asc-color-base-shade2);
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  background-color: rgb(0 0 0 / 20%);
  background-clip: padding-box;
  border: 1px solid rgb(255 255 255 / 20%);
  padding: 0.5rem 0.75rem;
  color: white;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: rgb(0 0 0 / 30%);
}

.button:active {
  background-color: rgb(0 0 0 / 40%);
}

.button:focus-visible {
  box-shadow: 0 0 0 2px rgb(255 255 255 / 75%);
}

.popover {
  padding: 0.25rem;
  width: 14rem;
  overflow: auto;
  border-radius: 0.375rem;
  background-color: white;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 10%),
    0 4px 6px -2px rgb(0 0 0 / 5%);
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%);
}

.popover[data-entering] {
  animation:
    fade-in 0.2s ease-out,
    zoom-in 0.2s ease-out;
}

.popover[data-exiting] {
  animation:
    fade-out 0.2s ease-in,
    zoom-out 0.2s ease-in;
}

.menu {
  outline: none;
}

.menuItem {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  color: var(--asc-color-secondary-default);
}

.menuItem:focus {
  background-color: var(--asc-color-base-shade1);
  color: white;
}

.separator {
  background-color: #d1d5db;
  height: 1px;
  margin: 0.25rem 0.75rem;
}

.postContent__notMember {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.25rem;
  padding-top: 0.25rem;
  line-height: 1.25rem;
  color: var(--asc-color-base-shade2);
}

.postContent__wrapRightMenu {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0.95);
  }

  to {
    transform: scale(1);
  }
}

@keyframes zoom-out {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.95);
  }
}
