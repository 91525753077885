.createStoryButton {
  display: flex;
  padding: 0.75rem 0;
  width: 100%;
  cursor: pointer;
}

.createStoryButton__text {
  margin-left: 0.5rem;
  color: var(--asc-color-base-default);
}

.createStoryButton__icon {
  fill: var(--asc-color-base-default);
  width: 1.25rem;
  height: 1.25rem;
}
