.createPostButton {
  display: flex;
  width: 100%;
  padding: 0.75rem 0;
  cursor: pointer;
}

.createPostButton__text {
  margin-left: 0.5rem;
  color: var(--asc-color-base-default);
}

.createPostButton__icon {
  fill: var(--asc-color-base-default);
  width: 1.25rem;
  height: 1.25rem;
}
