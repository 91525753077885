.communityCategories {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.25rem;
}

.communityCategories__categoryChip {
  width: unset;
}

.communityCategories__categoryOverflow {
  width: min-content;
}
