.detailedMediaAttachment {
  display: block;
  width: 100%;
  background-color: var(--asc-color-background-default);
}

.detailedMediaAttachment__swipeDown {
  display: flex;
  width: 2.25rem;
  height: 0.25rem;
  margin: 0 auto;
  border-radius: 0.75rem;
  background-color: var(--asc-color-base-shade3);
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
}
