.avatarContainer {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.avatarContainer[data-clickable='true']:hover {
  cursor: pointer;
}

.avatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
