.communityJoinedButton {
  display: flex;
  width: 100%;
  background: var(--asc-color-background-transparent-white);
  border: 1px solid var(--asc-color-base-shade4);
  padding: 0.625rem 1rem 0.625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.joinedButton {
  width: 1.25rem;
  height: 1rem;
  fill: var(--asc-color-white);
}

.checkButton {
  width: 1.25rem;
  height: 1rem;
  fill: var(--asc-color-black);
}

.communityJoinedButton__text {
  color: var(--asc-color-black);

  /* IOS / 13 Caption Bold */
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem; /* 138.462% */
  letter-spacing: -0.005rem;
}
