.commentButton {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.25rem;
  cursor: pointer;
}

.commentButton__icon {
  fill: var(--asc-color-base-shade2);
}

.commentButton__text {
  color: var(--asc-color-base-shade2);
}
