.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--asc-color-black);
  z-index: 1000;
}

.modalContent {
  background-color: var(--asc-color-background-default);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.closeButton {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 24px;
  cursor: pointer;
}

.fullImage {
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.nextButton {
  width: 2rem;
  height: 2rem;
}

.prevButton {
  width: 2rem;
  height: 2rem;
}

.overlayPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.overlayPanel__prev,
.overlayPanel__next {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0.5;
  background-color: var(--asc-color-black);
  flex-basis: 2.5rem;
  cursor: pointer;
}

.overlayPanel__prev:hover,
.overlayPanel__next:hover {
  opacity: 0.8;
}

.overlayPanel__prevButton,
.overlayPanel__nextButton {
  width: 2rem;
  height: 2rem;
  fill: var(--asc-color-base-shade4);
  border-radius: 50%;
  background-color: var(--asc-color-base-shade3);
  padding: 0.375rem;
}

.overlayPanel__prevButton {
  transform: rotate(180deg);
}

.overlayPanel__middle {
  flex: 1 1 auto;
}

.imageViewer__clearButton {
  fill: var(--asc-color-base-shade3);
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.imageViewer__clearButton__img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
