.userItem {
  display: grid;
  grid-template-columns: 3.75rem minmax(0, 1fr);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  cursor: pointer;
}

.userItem__leftPane {
  display: flex;
  justify-content: center;
  align-items: center;
}

.userItem__rightPane {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.25rem;
}

.userItem__avatar {
  width: 2.5rem;
  height: 2.5rem;
}

.userItem__brandIcon__container {
  width: 1.125rem;
  height: 1.125rem;
}

.userItem__brandIcon {
  width: 1.125rem;
  height: 1.125rem;
}

.userItem__userName {
  display: flex;
  justify-content: start;
  align-items: last baseline baseline;
  gap: 0.25rem;
  width: 100%;
  color: var(--asc-color-base-default);
  overflow: hidden;
  text-overflow: ellipsis;
}

.userItem__userName__text {
  overflow: hidden;
  text-overflow: ellipsis;
}
