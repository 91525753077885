.imageContent {
  display: grid;
  border-radius: 0.5rem;
  gap: 0.25rem;
  width: 100%;
  overflow: hidden;
}

.imageContent[data-images-amount='1'] {
  aspect-ratio: 1;
  grid-template: 'image1' / minmax(0, 1fr);
}

.imageContent[data-images-amount='2'] {
  aspect-ratio: 1;
  grid-template:
    'image1 image2' 50%
    'image1 image2' 50%
    / 50% 50%;
}

.imageContent[data-images-amount='3'] {
  aspect-ratio: 1;
  grid-template:
    'image1 image1 image1 image1' 66%
    'image2 image2 image3 image3' 33%
    / 25% 25% 25% 25%;
}

.imageContent[data-images-amount='4'] {
  aspect-ratio: 1;
  grid-template:
    'image1 image1 image1 image1 image1 image1' 66%
    'image2 image2 image3 image3 image4 image4' 33%
    / minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}

.imageContent__img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}

.imageContent__imgContainer {
  cursor: pointer;
}

.imageContent__imgContainer:nth-child(1) {
  grid-area: image1;
}

.imageContent__imgContainer:nth-child(2) {
  grid-area: image2;
}

.imageContent__imgContainer:nth-child(3) {
  grid-area: image3;
}

.imageContent__imgContainer:nth-child(4) {
  grid-area: image4;
  position: relative;
}

.imageContent__imgCover {
  position: absolute;
  inset: 0;
  border-radius: 0 0 0.5rem;
  background-color: rgb(0 0 0 / 50%);
  color: var(--asc-color-white);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
