.communityRowItemSkeleton {
  width: 100%;
  height: 5rem;
  border-radius: 0.75rem;
  display: inline-flex;
  gap: 1rem;
}

.communityRowItemSkeleton__avatar {
  width: 5rem;
  height: 5rem;
  border-radius: var(--asc-border-radius-sm);
  background-color: var(--asc-color-base-shade4);
}

.communityRowItemSkeleton__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;
}

.communityRowItemSkeleton__contentBar1 {
  border-radius: 0.75rem;
  background-color: var(--asc-color-base-shade4);
  width: 12.25rem;
  height: 0.75rem;
}

.communityRowItemSkeleton__contentBar2 {
  border-radius: 0.75rem;
  background-color: var(--asc-color-base-shade4);
  width: 5.8008rem;
  height: 0.625rem;
}

.communityRowItemSkeleton__animation {
  animation: skeleton-pulse 1.5s ease-in-out infinite;
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}
