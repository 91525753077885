.hyperlink {
  color: var(--asc-color-secondary-default);
  background: #fffc;
  display: inline-flex;
  align-items: center;
  padding: var(--asc-spacing-s2) var(--asc-spacing-m1) var(--asc-spacing-s2) var(--asc-spacing-s2);
  border-radius: var(--asc-border-radius-xxl);
  gap: var(--asc-spacing-s1);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
  max-width: 200px;
  border: var(--asc-color-base-shade4);
  text-decoration: none;
  cursor: pointer;
}

.hyperlinkIcon {
  color: var(--asc-color-primary-default);
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}

.hyperlinkText {
  display: flex;
  align-items: center;
  max-width: calc(100% - 2rem);
  overflow: hidden;
  color: var(--asc-color-secondary-default);
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
