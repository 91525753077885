.errorIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

.addStoryButton {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

.hiddenInput {
  display: none;
}

.storyWrapper {
  width: 3rem;
  height: 3rem;
  position: relative;
  cursor: pointer;
}

.storyTabContainer {
  background-color: var(--asc-color-background-default);
  position: relative;
  width: 3rem;
  display: flex;
  gap: 0.13rem;
  flex-direction: column;
  text-align: center;
  padding: 1rem 0.75rem;
  align-items: center;
}

.storyAvatarContainer {
  all: unset;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
}

.storyAvatar {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 50%;
}

.storyTitle {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #626262;
  cursor: pointer;
}

@keyframes animate-ring {
  0% {
    stroke-dashoffset: 339;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.progressRing {
  animation: animate-ring 2s linear 0s infinite;
}

.progressRingStatic {
  animation: none;
}
