.icon__container {
  display: flex;
  padding: var(--asc-spacing-m1) var(--asc-spacing-s2);
  align-items: center;
  gap: var(--asc-spacing-s1);
  align-self: stretch;
}

.icon {
  width: var(--asc-spacing-m2);
  height: var(--asc-spacing-m2);
}

.notifications {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99999;
  pointer-events: none;
  padding: var(--asc-spacing-m1);
}

.notificationContainer {
  width: 21.438rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--asc-color-white);
  border-radius: var(--asc-border-radius-md);
  margin-bottom: var(--asc-spacing-m1);
  animation-duration: 0.3s;
  animation-name: appear;
  pointer-events: auto;
  background-color: var(--asc-color-secondary-default);
  box-shadow: var(--asc-box-shadow-02);
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (width <= 768px) {
  .notifications {
    top: auto;
    bottom: 0;
    padding-bottom: var(--asc-spacing-l1);
  }

  .notificationContainer {
    width: 100%;
    margin-bottom: var(--asc-spacing-none);
  }
}
