.reactionListContainer {
  display: flex;
  flex-direction: column;
  gap: var(--asc-spacing-m1);
  height: 100%;
}

.tabListContainer {
  display: flex;
  align-items: center;
  overflow-x: auto;
  position: relative;
  width: 100%;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.tabListContainer::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.tabList {
  display: flex;
  gap: var(--asc-spacing-s1);
  border-bottom: 1px solid var(--asc-color-base-shade4);
  width: 100%;
  min-width: max-content;
}

.tabItem {
  cursor: pointer;
  padding: var(--asc-spacing-xxs2) var(--asc-spacing-s1);
  background: var(--asc-color-background-default);
  color: var(--asc-color-base-shade6);
  padding-bottom: var(--asc-spacing-s1);
  border-bottom: transparent;
}

.tabItem[data-active='true'] {
  color: var(--asc-color-primary-default);
  border-bottom: 1px solid var(--asc-color-primary-default);
}

.reactionEmoji {
  display: flex;
  align-items: center;
  gap: var(--asc-spacing-s1);
}

.userList {
  display: flex;
  gap: var(--asc-spacing-s1);
  width: 100%;
  flex-direction: column;
}

.userItem {
  display: flex;
  align-items: center;
  gap: var(--asc-spacing-s1);
  background: var(--asc-color-background-default);
  border-radius: var(--asc-border-radius-sm);
  width: 100%;
  border-bottom: 1px solid var(--asc-color-base-shade4);
}

.userDetailsContainer {
  display: flex;
  color: var(--asc-color-base-default);
  padding: var(--asc-spacing-s1);
  justify-content: space-between;
  width: 100%;
}

.userDetailsProfile {
  display: flex;
  gap: var(--asc-spacing-s2);
  align-items: center;
}

.userDetailsReaction {
  display: flex;
  align-items: center;
}

.reactionItem {
  width: 1.25rem;
}

.reactionIcon {
  width: 1.5rem;
}

.reactionCustomStateContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--asc-color-base-shade2);
}

.reactionCustomStateContainer.loadingState {
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  animation: skeleton-pulse 1.5s ease-in-out infinite;
}

.reactionState {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--asc-spacing-s2);
}

.reactionState2Line {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--asc-spacing-xxs2);
}

.removeBtn {
  cursor: pointer;
  color: var(--asc-color-base-shade1);
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

.infiniteScrollContainer {
  display: flex;
  flex-grow: 1;
  overflow: auto;
  width: 100%;

  > div {
    width: 100%;
  }
}

.reactionPanel {
  height: 100%;
}

.avatar {
  width: 2rem;
  height: 2rem;
}
