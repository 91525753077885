.topSearchBar {
  display: grid;
  grid-template-columns: minmax(0, 1fr) min-content;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--asc-color-background-default);
}

.topSearchBar__inputBar {
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  align-items: center;
  background-color: var(--asc-color-base-shade4);
  gap: 0.5rem;
  padding: 0.62rem 0.75rem;
  border-radius: 0.5rem;
}

.topSearchBar__textInput {
  border: none;
  background-color: var(--asc-color-base-shade4);
  color: var(--asc-color-base-shade2);
  outline: none;
}

.topSearchBar__searchIcon {
  fill: var(--asc-color-base-default);
  width: 1.25rem;
  height: 1.25rem;
}

.topSearchBar__searchIcon_img {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.topSearchBar__clearButton {
  fill: var(--asc-color-base-shade3);
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.topSearchBar__clearButton__img {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
