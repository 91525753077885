.hyperlinkFormContainer {
  background-color: transparent;
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--asc-spacing-l1);
}

.bottomSheet .react-modal-sheet-backdrop {
  background-color: rgb(0 0 0 / 50%);
}

.bottomSheet .react-modal-sheet-container {
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-height: 80%;
  overflow: auto;
}

.bottomSheet .react-modal-sheet-content {
  background-color: var(--asc-color-background-default);
  padding: 16px;
}

.bottomSheet .react-modal-sheet-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomSheet .react-modal-sheet-drag-indicator {
  width: 40px;
  height: 4px;
  background-color: #ccc;
  border-radius: 2px;
  margin-bottom: 8px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: var(--asc-spacing-xxs2);
}

.input {
  width: 100%;
  padding: var(--asc-spacing-s1);
  border: none;
  border-bottom: 1px solid var(--asc-color-base-shade4);
  outline: none;
  color: var(--asc-color-base-default);
  background-color: transparent;
}

.input.hasError {
  border-bottom-color: var(--asc-color-alert-default);
  color: var(--asc-color-alert-default);
}

.label {
  display: block;
}

.label::after {
  content: none;
  color: var(--asc-color-alert-default);
}

.label.required::after {
  content: '*';
  color: var(--asc-color-alert-default);
}

.description {
  color: var(--asc-color-base-shade2);
}

.errorText {
  color: var(--asc-color-alert-default);
}

.characterCount {
  color: var(--asc-color-base-shade1);
  text-align: right;
  margin-top: 0.3rem;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  color: var(--asc-color-base-default);
}

.cancelButton {
  border: none;
  color: var(--asc-color-base-default);
}

.doneButton {
  border: none;
}

.doneButton:disabled {
  cursor: not-allowed;
  color: var(--asc-color-primary-shade2);
}

.removeIcon {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--asc-color-alert-default);
}

.removeLinkButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--asc-spacing-s1);
  color: var(--asc-color-alert-default);
  border-radius: 0;
  background-color: transparent;
  transition: color 0.3s ease;
  border: none;
  font-weight: var(--asc-text-font-weight-normal);
}

.divider {
  width: 100%;
  height: 0.0625rem;
  align-self: stretch;
  background-color: var(--asc-color-base-shade4);
  margin: 1rem 0;
}

.removeLinkContainer {
  margin-top: var(--asc-spacing-l1);
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--asc-color-base-shade4);
}
