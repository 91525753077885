.imageButton {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 0.75rem;
  cursor: pointer;
}

.imageButton__icon {
  width: 2rem;
  height: 2rem;
  stroke: var(--asc-color-base-default);
  background: var(--asc-color-base-shade4);
  border-radius: 50%;
  padding: 0.25rem;
}

.imageButton_input {
  display: none;
}
