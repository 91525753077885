.mediaAttachment {
  display: block;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mediaAttachment__swipeDown {
  display: block;
  width: 2.25rem;
  height: 0.25rem;
  margin: 0 auto;
  border-radius: 0.75rem;
  background-color: var(--asc-color-base-shade3);
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}

.mediaAttachment__wrapMedia {
  display: flex;
  justify-content: space-between;
}

.mediaAttachment__wrapMedia_2items {
  display: flex;
  justify-content: space-around;
}
